@import "modules/responsive-type.scss";
@import url('https://fonts.googleapis.com/css?family=Cookie|Economica:400,700');

$primary: #425931;/* MAIN COLOR */
$secondary: #99BF9F; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes.

$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

html, body {
    height: 100%;
    font-family: 'Economica', sans-serif;
    h1,h2,h3,h4,h5,h6 {
      font-family: 'Cookie', cursive;
    }
}

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p {
    font-size: 1rem;
}

h1 {
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h2 {
  font-size: $mod_1*$mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}
h3 {
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}

/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;

    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 22px;
            display: flex;
            align-items: center;
            color: $blk;
            font-size: 1.4em;
            @media (max-width: 767px) {
                margin-top: 0;
                padding:8px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
              border-left: 1px dashed $primary;
              border-top: 1px dashed $primary;
                color: $blk;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 1em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 1em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;
        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 100%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/
// section start
section {
  padding: 50px 0;
}
.section-a {
  background-image: url("../img/bg1.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 350px 0;
  color: darken($blk, 25%);
  @media(max-width: 1024px){
    padding: 200px 0;
  }
  @media(max-width:767px){
    padding:120px 0;
    color: $wht;
  }




}
.section-b {
  background-color: $primary;
  background-image: url("https://www.transparenttextures.com/patterns/fabric-plaid.png");
  color:$wht;
  padding: 150px 0;
  @media(max-width: 767px){
    padding: 80px 0;
  }
  @media(max-width: 500px){
    padding: 40px 0;
  }
}
.section-c {
  padding: 150px 0;
  @media(max-width: 767px){
    padding: 80px 0;
  }
  @media(max-width: 500px){
    padding: 40px 0;
  }

}
.section-d {
  background-color: $primary;
  background-image: url("https://www.transparenttextures.com/patterns/fabric-plaid.png");
  img {
  }
}
// section ends

footer {
    padding: 50px 0px 20px;
    background: $primary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 125px;
    }
    .navbar-toggle {
        margin-top: 5px;
    }
}

.top-pad {
    padding: 3em;
    background: white;

    @media (max-width: 990px) {
        padding: 1em;
    }
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media  (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */
    }
}

.terms,
.privacy {
        font-size: 1rem;

    & ol {
        li {
            font-size: 1rem;
        }
        & ol li {
            font-size: 0.85rem;
        }
    }
}
